/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { Input } from 'styleguide/components/forms';
import IconSearch from 'styleguide/icons/IconSearch';

interface Props {
  placeholder: string;
  handleSearch: (value) => void;
  dataCy?: string;
}

interface State {
  value: string;
}

class Search extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { value: '' };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ value: event.target.value });
    this.props.handleSearch(event.target.value);
  };

  render() {
    return (
      <Input
        size="md"
        value={this.state.value}
        label={this.props.placeholder}
        iconPlacement="left"
        Icon={IconSearch}
        onChange={this.handleChange}
        data-cy={this.props.dataCy}
      />
    );
  }
}

export default Search;
